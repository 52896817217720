import ThemisInput from "@/components/shared/input"
export default {
  name      : "AiFeedbackConfirmation",
  components: {
    ThemisInput
  },
  data: () => ({
    feedbackOption    : null,
    feedbackDetailText: ""
  }),
  emits: ["cancelAiFeedback"],
  props: {
    pAiFeedback          : Object,
    pAiFeedbackProperties: Object
  },
  computed: {
  },
  methods: {
    handleCancelAiFeedback() {
      this.feedbackOption     = null
      this.feedbackDetailText = ""
      this.$emit("cancelAiFeedback")
    },
    handleSubmitAiFeedback() {
      if (this.pAiFeedback.feedback.length === 1 &&
        this.pAiFeedback.feedback[0].question === this.pAiFeedbackProperties.aiFeedBackQuestions.FIRST) {
        this.$emit("submitAiFeedback", {
          type    : this.pAiFeedback.type,
          uuid    : this.pAiFeedback.uuid,
          feedback: [
            ...this.pAiFeedback.feedback,
            {
              question: this.pAiFeedbackProperties.aiFeedBackQuestions.SECOND,
              answer  : this.feedbackOption
            },
            {
              question: this.pAiFeedbackProperties.aiFeedBackQuestions.THIRD,
              answer  : this.feedbackDetailText
            }
          ]
        })
      }
      this.feedbackOption     = null
      this.feedbackDetailText = ""
    }
  },
  watch: {

  }
}